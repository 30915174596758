import { graphql } from 'gatsby'
import { FluidObject } from 'gatsby-image'
import React from 'react'
import Content from '../components/Content'
import Copy from '../components/Copy'
import Stage from '../components/Stage'
import Default from '../templates/default'

export interface ImpressumProps {
    data: {
        stageImage: {
            childImageSharp: {
                fluid: FluidObject
            }
        }
    }
}

const Impressum = ({ data }: ImpressumProps): JSX.Element => (
    <Default>
        <Content align="center">
            <Stage image={data.stageImage.childImageSharp.fluid} small={true} />

            <Copy>
                Dieser Inhalt wird verantwortet von:
                <br />
                <br />
                <strong>Bride Surprise</strong>
                <br />
                Ann-Cathrin Krauß
                <br />
                Bayenthalgürtel 63
                <br />
                50968 Köln
                <br />
                <br />
                Telefon: +49 (0) 221 168 681 30
                <br />
                E-Mail: hallo@bride-surprise.com
                <br />
                <br />
                <br />
                <strong>Verantwortlich gemäß § 55 RStV</strong>
                <br />
                Matthias Blank, Ann-Cathrin Krauß, Jan Sagemüller
                <br />
                Umsatzsteuer-Identifikationsnummer: DE 292 921 426
                <br />
                <br />
                Haftungshinweis: Copyright 2019 Bride Surprise.
                <br />
                Alle Informationen und Inhalte auf der Bride Surprise Seiten, dienen der Information über unser Angebot
                und unterliegen dem Schutz des Urheberrechts. Die Inhalte dürfen weder vollständig noch teilweise ohne
                die schriftliche Genehmigung von Bride Surprise vervielfältigt, verbreitet, gespeichert, verändert,
                übersetzt oder in sonstiger Art und Weise verwendet werden. Einige Bereiche der Website enthalten
                außerdem Bilder, die dem Copyright Dritter unterliegen.
                <br />
                <br />
                Die Informationen, welche auf dieser Website angeboten werden, wurden sorgfältig geprüft. Eine Garantie
                für die Richtigkeit, Vollständigkeit und Aktualität können wir jedoch nicht übernehmen. Bride Surprise
                schließt die Haftung für eventuell entstehende Schäden, welche auf Grund von der Nutzung dieser Website
                entstehen aus, sofern sie nicht auf Vorsatz oder grober Fahrlässigkeit von Bride Surprise beruhen.
                <br />
                <br />
                <br />
                <strong>Bildnachweis:</strong>
                <br />
                <a target="_blank" rel="noreferrer" href="https://www.octaviaplusklaus.com">
                    OctaviaplusKlaus
                </a>
                <br />
                <a target="_blank" rel="noreferrer" href="https://www.tausendschoen-photographie.de">
                    Tanja Wesel
                </a>
                <br />
                <a target="_blank" rel="noreferrer" href="https://shutterstock.com">
                    Shutterstock
                </a>
            </Copy>
        </Content>
    </Default>
)

export const query = graphql`
    query {
        stageImage: file(relativePath: { eq: "stage/kontakt.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1200, maxHeight: 250, quality: 75, cropFocus: CENTER) {
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                }
            }
        }
    }
`

export default Impressum
